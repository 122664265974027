a {
  color: var(--primary);
  transition: color var(--transition);
  overflow-wrap: break-word;
}

a:hover {
  color: var(--text);
}

a:not([href]) {
  color: var(--text);
}
